import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Feedback | EcoDrive Service Center
			</title>
			<meta name={"description"} content={"Найкращий показник якості EcoDrive Service Center – це задоволені клієнти."} />
			<meta property={"og:title"} content={"Feedback | EcoDrive Service Center"} />
			<meta property={"og:description"} content={"Найкращий показник якості EcoDrive Service Center – це задоволені клієнти."} />
			<link rel={"shortcut icon"} href={"https://arataplesta.com/imgs/6456819.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileColor"} content={"https://arataplesta.com/imgs/6456819.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Reviews-3">
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 20px/1.5 --fontFamily-sansHelvetica"
				color="--indigo"
				lg-margin="0px 0px 20px 0px"
				text-align="center"
			>
				Testimonials
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center" md-margin="0px 0px 50px 0px">
				Найкращий показник якості{" "}
				<br />
				{" "}– це задоволені клієнти
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 30px"
				justify-content="center"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-grid-gap="30px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Я обслуговую свій автомобіль в EcoDrive Service Center вже кілька років, і завжди залишаюся задоволеною. Вони не лише встановили систему ГБО на моєму авто, але і регулярно проводять його обслуговування. Їхні фахівці завжди готові відповісти на мої запитання і надають корисні поради щодо підтримки автомобіля. Рекомендую їх всім, хто шукає надійний сервіс.{"\n\n"}
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://arataplesta.com/imgs/feedback1.jpg"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Ірина П.{"\n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Велике спасибі EcoDrive Service Center за чудовий сервіс! Установка ГБО виявилася великим економічним кроком для мене, і завдяки їхнім професіоналам, вона пройшла без проблем. Я також використовував їхні послуги з регулярного обслуговування автомобіля, і завжди залишався вражений їхньою увагою до деталей.{"\n\n"}
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://arataplesta.com/imgs/feedback2.jpg"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Андрій Г.{"\n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Я вперше вирішила встановити систему ГБО і була трохи схвильована. EcoDrive Service Center рекомендували мені друзі, і я не розчарувалася. Вони провели встановлення професійно і після цього навчили мене, як правильно використовувати цю систему. Тепер мій автомобіль економить пальне, і я більш ніж задоволена результатами.{"\n\n"}
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://arataplesta.com/imgs/feedback3.jpg"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Ольга С.{"\n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Коли мій автомобіль потребував серйозного ремонту, я звернувся до EcoDrive Service Center і не пошкодував. Вони виявилися висококваліфікованою командою, яка оперативно вирішила проблему та зробила мій автомобіль як новий. Тепер це мій пункт обслуговування номер один.{"\n\n"}
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://arataplesta.com/imgs/feedback4.jpg"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Максим К.{"\n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523c308e2dd3a0022204417"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});